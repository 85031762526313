import React from 'react'
import { graphql } from 'gatsby'
// import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styles from './index.module.css'
import Layout from '../components/Layout/'
import Markdown from 'markdown-to-jsx'

const NewsTemplate = ({ data }) => (
  <Layout>
    <div className={styles.container}>
      <h1>{data.strapiNews.title}</h1>
      {/* <p>by <Link to={`/authors/User_${data.strapiNews.author.id}`}>{data.strapiNews.author.username}</Link></p> */}
      {/* <Img fixed={data.strapiNews.image.childImageSharp.fixed}/> */}
      {data.strapiNews.cover !== null && (
        <Img
          className={styles.newsImg}
          fluid={data.strapiNews.cover.childImageSharp.fluid}
          alt={data.strapiNews.title}
        />
      )}
      <Markdown>{data.strapiNews.content}</Markdown>
    </div>
  </Layout>
)

export default NewsTemplate

export const query = graphql`
  query NewsTemplate($id: String!) {
    strapiNews(id: { eq: $id }) {
      title
      content
      cover {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      user {
        id
        username
      }
    }
  }
`
